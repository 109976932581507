/* eslint-disable no-unused-vars */
import { computed } from "@vue/composition-api";
import useFormState from "../state";
import store from "../../../store";

export default function useFormPopulatedJobFields(props) {
  const formState = useFormState(props);

  function processPopulatedJobFields() {
    const positiveValues = ["ja", "yes", "true", "1"];

    formState.orderIntake.value.order.invoicing_eid =
      store.getters.externalInvoicingId || null;
    formState.orderIntake.value.order.eid = store.getters.eid || null;
    formState.orderIntake.value.order.brand2 = store.getters.brand2 || null;
    formState.orderIntake.value.order.internal_comments =
      store.getters.internal_comments || null;
    formState.orderIntake.value.order.own_installer =
      positiveValues.includes(store.getters.ownInstaller?.toLowerCase()) ||
      false;
  }

  return {
    processPopulatedJobFields,
  };
}
