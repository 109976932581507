/* eslint-disable no-unused-vars */
import { computed } from "@vue/composition-api";
import useFormState from "../state";
import store from "../../../store";
import { CustomerSignatureStatusEnum } from "@gid/models";

export default function useFormPopulatedJobFields(props) {
  const formState = useFormState(props);

  function processPopulatedJobFields() {
    if (store.getters.customerSignature) {
      formState.orderIntake.value.order.inputs.customer_signature =
        CustomerSignatureStatusEnum.TAKEN;
      formState.orderIntake.value.order.inputs.customer_signature_reason = null;
    }
  }

  return {
    processPopulatedJobFields,
  };
}
