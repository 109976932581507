/* eslint-disable no-unused-vars */
import Vue from "vue";
import {
  AFFILIATE_ID_SET,
  ANSWER_APPEND,
  ANSWER_SET,
  AUTH_SET,
  BRAND2_SET,
  CUSTOMER_SET,
  EID_SET,
  EXTERNAL_INVOICING_ID_SET,
  CUSTOMER_SIGNATURE_SET,
  FLOWS_SET,
  FORM_ERRORS_SET,
  FORM_SETTINGS_SET,
  HISTORY_FLOWS_ADD,
  INTERNAL_COMMENT_SET,
  ORDER_SET,
  OWN_INSTALLER_SET,
  PLANNING_OPPORTUNITY_SET,
  PRICING_PRICE_MAX_SET,
  PRICING_PRICE_MIN_SET,
  PRICING_PRICE_SET,
  PRICING_REQUEST_ADD,
  PRICING_REQUEST_REMOVE,
  PROJECT_ID_SET,
  TRANSLATION_SET,
  WIZARD_ITEM_VISIBLE_SET,
  WIZARD_ITEMS_SET,
} from "./types";
import { UserRoleEnum } from "@gid/models";

export default {
  [WIZARD_ITEMS_SET](state, payload) {
    state.wizardItems = payload.wizardItems;
  },
  [ANSWER_SET](state, payload) {
    state.wizardItems[payload.globalIndex].answer = payload.answer;
    state.wizardItems[payload.globalIndex].errors = [];
  },
  [ANSWER_APPEND](state, payload) {
    state.wizardItems[payload.globalIndex].answer.push(payload.answer);
    state.wizardItems[payload.globalIndex].errors = [];
  },
  [WIZARD_ITEM_VISIBLE_SET](state, payload) {
    Vue.set(state.wizardItems[payload.globalIndex], "visible", payload.visible);
  },
  [FLOWS_SET](state, payload) {
    state.flows = payload.flows;
  },
  [PLANNING_OPPORTUNITY_SET](state, payload) {
    state.opportunity = payload.opportunity;
  },
  [HISTORY_FLOWS_ADD](state, payload) {
    state.history.flows.push(payload);
  },
  [FORM_SETTINGS_SET](state, payload) {
    state.settings.form = payload;
  },
  [TRANSLATION_SET](state, payload) {
    state.translation = payload;
  },
  [CUSTOMER_SET](state, payload) {
    state.customer = payload;
  },
  [ORDER_SET](state, payload) {
    state.order = payload;
  },
  [AUTH_SET](state, payload) {
    state.auth = payload;
  },
  [AFFILIATE_ID_SET](state, payload) {
    state.affiliateId = payload;
  },
  [EXTERNAL_INVOICING_ID_SET](state, payload) {
    state.externalInvoicingId = payload;
  },
  [CUSTOMER_SIGNATURE_SET](state, payload) {
    state.customerSignature = !!payload;
  },
  [EID_SET](state, payload) {
    state.eid = payload;
  },
  [PROJECT_ID_SET](state, payload) {
    state.projectId = payload;
  },
  [BRAND2_SET](state, payload) {
    state.brand2 = payload;
  },
  [OWN_INSTALLER_SET](state, payload) {
    state.ownInstaller = payload;
  },
  [INTERNAL_COMMENT_SET](state, payload) {
    state.internal_comments = {
      message: payload,
      visible_for_roles: Object.values(UserRoleEnum),
      include_in_protocol: false,
    };
  },
  [PRICING_REQUEST_ADD](state, payload) {
    if (!state.pricing.requests.find((x) => x.checksum === payload.checksum)) {
      state.pricing.requests.push(payload);
    }
  },
  [PRICING_REQUEST_REMOVE](state, payload) {
    const foundIndex = state.pricing.requests.findIndex(
      (x) => x.checksum === payload.checksum
    );
    if (foundIndex > -1) {
      state.pricing.requests.splice(foundIndex, 1);
    }
  },
  [PRICING_PRICE_SET](state, payload) {
    state.pricing.price = payload;
  },
  [PRICING_PRICE_MIN_SET](state, payload) {
    state.pricing.priceMin = payload;
  },
  [PRICING_PRICE_MAX_SET](state, payload) {
    state.pricing.priceMax = payload;
  },
  [FORM_ERRORS_SET](state, payload) {
    let temp = {};
    for (const key in payload) {
      if (!Array.isArray(payload[key])) {
        temp = { ...temp, ...payload[key] };
      } else {
        temp[key] = payload[key];
      }
    }

    const apiErrors = {
      phone: {
        required_phone_number: "You must provide email or at least one phone",
        invalid_phone_number_format:
          "Invalid phone number, must start with + or 0  and use only following chars:'0-9 +-/()'",
        invalid_phone_number: "Invalid phone number",
      },
      email: {
        required_email: "You must provide email or at least one phone",
        invalid_email: "Not a valid email address.",
        invalid_email_domain:
          "Email is invalid: The domain name {0} does not exist.",
      },
      postal_code: {
        required_postal_code: "Field may not be null.",
        invalid_postal_code: "Invalid or unknown postcode for selected country",
      },
      street: {
        required_street: "Field may not be null.",
      },
      first_name: {
        required_first_name: "Field may not be null.",
      },
      last_name: {
        required_last_name: "Field may not be null.",
      },
    };
    const errorKeys = Object.keys(temp);
    state.wizardItems = state.wizardItems.map((item) => {
      errorKeys.forEach((key) => {
        if (item.name == key) {
          if (Array.isArray(temp[key])) {
            if (Object.keys(apiErrors).includes(key)) {
              Object.keys(apiErrors[key]).forEach((x) => {
                temp[key].forEach((y) => {
                  if (apiErrors[key][x] === y) {
                    item.errors.push(x);
                  } else if (key === "email") {
                    if (
                      apiErrors[key][x].startsWith(
                        "Email is invalid: The domain name"
                      )
                    ) {
                      item.errors.push("invalid_email_domain");
                    }
                  }
                });
              });
            } else {
              item.errors = temp[key];
            }
          } else {
            item.errors = temp[key];
          }
        }
      });
      item.errors = Array.from(new Set(item.errors));
      return item;
    });

    if (errorKeys.includes("general")) {
      state.errors = temp.general;
    }
  },
};
