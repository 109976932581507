/* eslint-disable no-unused-vars */
import {
  WIZARD_ITEMS_SET,
  ANSWER_SET,
  ANSWER_APPEND,
  FLOWS_SET,
  PLANNING_OPPORTUNITY_SET,
  TRANSLATION_SET,
  FORM_SETTINGS_SET,
  FORM_ERRORS_SET,
  HISTORY_FLOWS_ADD,
  CUSTOMER_SET,
  ORDER_SET,
  AUTH_SET,
  AFFILIATE_ID_SET,
  EXTERNAL_INVOICING_ID_SET,
  CUSTOMER_SIGNATURE_SET,
  WIZARD_ITEM_VISIBLE_SET,
  PRICING_REQUEST_ADD,
  PRICING_REQUEST_REMOVE,
  PRICING_PRICE_SET,
  PRICING_PRICE_MIN_SET,
  PRICING_PRICE_MAX_SET,
  EID_SET,
  PROJECT_ID_SET,
  BRAND2_SET,
  INTERNAL_COMMENT_SET,
  POPULATE_QUESTION,
  OWN_INSTALLER_SET,
} from "./types";

export default {
  [POPULATE_QUESTION]: ({ commit, state }, { answer, globalIndex }) => {
    commit(ANSWER_SET, {
      globalIndex,
      answer,
    });
  },
  [WIZARD_ITEMS_SET]: ({ commit }, payload) => {
    commit(WIZARD_ITEMS_SET, payload);
  },
  [ANSWER_SET]: ({ commit, getters }, payload) => {
    payload.globalIndex = getters.allWizardItems.findIndex((wizardItem) => {
      return (
        wizardItem.opportunity === getters.opportunity.sfid &&
        wizardItem.id === payload.id
      );
    });
    if (payload.jobFieldPopulated) {
      commit(payload.jobFieldPopulated, payload.answer);
    }
    commit(ANSWER_SET, payload);
  },
  [ANSWER_APPEND]: ({ commit, getters }, payload) => {
    payload.globalIndex = getters.allWizardItems.findIndex((wizardItem) => {
      return (
        wizardItem.opportunity === getters.opportunity.sfid &&
        wizardItem.id === payload.id
      );
    });
    commit(ANSWER_APPEND, payload);
  },
  [WIZARD_ITEM_VISIBLE_SET]: ({ commit, getters }, payload) => {
    payload.globalIndex = getters.allWizardItems.findIndex((wizardItem) => {
      return (
        wizardItem.opportunity === getters.opportunity.sfid &&
        wizardItem.id === payload.id
      );
    });
    commit(WIZARD_ITEM_VISIBLE_SET, payload);
  },
  [FLOWS_SET]: ({ commit }, payload) => {
    commit(FLOWS_SET, payload);
  },
  [FLOWS_SET]: ({ commit }, payload) => {
    commit(FLOWS_SET, payload);
  },
  [PLANNING_OPPORTUNITY_SET]: ({ commit }, payload) => {
    commit(PLANNING_OPPORTUNITY_SET, payload);
  },
  [HISTORY_FLOWS_ADD]: ({ commit }, payload) => {
    commit(HISTORY_FLOWS_ADD, payload);
  },
  [FORM_SETTINGS_SET]: ({ commit }, payload) => {
    commit(FORM_SETTINGS_SET, payload);
  },
  [TRANSLATION_SET]: ({ commit }, payload) => {
    commit(TRANSLATION_SET, payload);
  },
  [CUSTOMER_SET]: ({ commit }, payload) => {
    commit(CUSTOMER_SET, payload);
  },
  [ORDER_SET]: ({ commit }, payload) => {
    commit(ORDER_SET, payload);
  },
  [AUTH_SET]: ({ commit }, payload) => {
    commit(AUTH_SET, payload);
  },
  [AFFILIATE_ID_SET]: ({ commit }, payload) => {
    commit(AFFILIATE_ID_SET, payload);
  },
  [EXTERNAL_INVOICING_ID_SET]: ({ commit }, payload) => {
    commit(EXTERNAL_INVOICING_ID_SET, payload);
  },
  [CUSTOMER_SIGNATURE_SET]: ({ commit }, payload) => {
    commit(CUSTOMER_SIGNATURE_SET, payload);
  },
  [EID_SET]: ({ commit }, payload) => {
    commit(EID_SET, payload);
  },
  [PROJECT_ID_SET]: ({ commit }, payload) => {
    commit(PROJECT_ID_SET, payload);
  },
  [BRAND2_SET]: ({ commit }, payload) => {
    commit(BRAND2_SET, payload);
  },
  [OWN_INSTALLER_SET]: ({ commit }, payload) => {
    commit(OWN_INSTALLER_SET, payload);
  },
  [INTERNAL_COMMENT_SET]: ({ commit }, payload) => {
    commit(INTERNAL_COMMENT_SET, payload);
  },
  [PRICING_REQUEST_ADD]: ({ commit }, payload) => {
    commit(PRICING_REQUEST_ADD, payload);
  },
  [PRICING_REQUEST_REMOVE]: ({ commit }, payload) => {
    commit(PRICING_REQUEST_REMOVE, payload);
  },
  [PRICING_PRICE_SET]: ({ commit }, payload) => {
    commit(PRICING_PRICE_SET, payload);
  },
  [PRICING_PRICE_MIN_SET]: ({ commit }, payload) => {
    commit(PRICING_PRICE_MIN_SET, payload);
  },
  [PRICING_PRICE_MAX_SET]: ({ commit }, payload) => {
    commit(PRICING_PRICE_MAX_SET, payload);
  },
  [FORM_ERRORS_SET]: ({ commit }, payload) => {
    commit(FORM_ERRORS_SET, payload);
  },
};
