export default {
  flows: [],
  wizardItems: [],
  opportunity: {},
  errors: [],
  history: {
    flows: [],
  },
  settings: {
    form: {
      name: "gardena",
      progress: false,
      price: true,
      priceEstimateFrom: null,
      summary: null,
      mapping: null,
    },
  },
  translation: {},
  customer: {
    email: null,
    customer_eid: null, // customer card number
  },
  order: {
    id: null,
    services: null,
  },
  auth: {
    accessToken: null,
  },
  affiliateId: null,
  externalInvoicingId: null,
  customerSignature: null,
  eid: null,
  projectId: null,
  brand2: null,
  ownInstaller: null,
  pricing: {
    requests: [],
    price: null,
    priceMin: null,
    priceMax: null,
  },
};
