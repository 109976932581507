export const ANSWER_SET = "ANSWER_SET",
  ANSWER_APPEND = "ANSWER_APPEND",
  FLOWS_GET = "FLOWS_GET",
  FLOWS_SET = "FLOWS_SET",
  WIZARD_ITEMS_SET = "WIZARD_ITEMS_SET",
  PLANNING_OPPORTUNITY_SET = "PLANNING_OPPORTUNITY_SET",
  FORM_SETTINGS_SET = "FORM_SETTINGS_SET",
  TRANSLATION_SET = "TRANSLATION_SET",
  FORM_ERRORS_SET = "FORM_ERRORS_SET",
  HISTORY_FLOWS_ADD = "HISTORY_FLOWS_ADD",
  CUSTOMER_SET = "CUSTOMER_SET",
  ORDER_SET = "ORDER_SET",
  AUTH_SET = "AUTH_SET",
  AFFILIATE_ID_SET = "AFFILIATE_ID_SET",
  EXTERNAL_INVOICING_ID_SET = "externalInvoicingId",
  CUSTOMER_SIGNATURE_SET = "customerSignature",
  WIZARD_ITEM_VISIBLE_SET = "WIZARD_ITEM_VISIBLE_SET",
  PRICING_REQUEST_ADD = "PRICING_REQUEST_ADD",
  PRICING_REQUEST_REMOVE = "PRICING_REQUEST_REMOVE",
  PRICING_PRICE_SET = "PRICING_PRICE_SET",
  PRICING_PRICE_MIN_SET = "PRICING_PRICE_MIN_SET",
  PRICING_PRICE_MAX_SET = "PRICING_PRICE_MAX_SET",
  EID_SET = "eid",
  PROJECT_ID_SET = "projectId",
  BRAND2_SET = "brand2",
  INTERNAL_COMMENT_SET = "internal_comments",
  OWN_INSTALLER_SET = "ownInstaller",
  POPULATE_QUESTION = "POPULATE_QUESTION";
