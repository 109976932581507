/* eslint-disable no-unused-vars */
import { computed } from "@vue/composition-api";
import useFormState from "../state";
import store from "../../../store";
import { JobStatusEnum, FormOrderModificationThroughEnum } from "@gid/models";

export default function useFormOrder(props, context) {
  const formState = useFormState(props);

  const formSettings = computed(() => {
    return store.getters.formSettings;
  });

  async function processOrderPayload() {
    if (
      [
        FormOrderModificationThroughEnum.VALUE_CHANGE,
        FormOrderModificationThroughEnum.STATUS_CHANGE,
      ].includes(formSettings.value.orderStrategy.modification.through)
    ) {
      formState.orderIntake.value.order.new_status =
        formSettings.value.orderStrategy.modification.newStatus;
      formState.orderIntake.value.order.job_id = store.getters.order.id;

      let inputs = {};
      if (
        [JobStatusEnum.APPROVAL_PENDING, JobStatusEnum.FINISHED].includes(
          formSettings.value.orderStrategy.modification.newStatus
        )
      ) {
        // finished timestamp
        inputs.finished_timestamp = context.root.$moment().format();
      }

      // professional comment
      const professionalComment = store.getters.wizardItemByName(
        "professional-comment"
      );
      if (professionalComment && professionalComment.answer) {
        inputs.professional_comment = professionalComment.answer;
      }

      // services
      if (
        Array.isArray(formState.orderIntake.value.order.inputs.services) &&
        formState.orderIntake.value.order.inputs.services.length
      ) {
        inputs.services = formState.orderIntake.value.order.inputs.services;
      } else {
        inputs.services = undefined;
      }

      // products
      if (
        Array.isArray(formState.orderIntake.value.order.inputs.products) &&
        formState.orderIntake.value.order.inputs.products.length
      ) {
        inputs.products = formState.orderIntake.value.order.inputs.products;
      } else {
        inputs.products = undefined;
      }

      formState.orderIntake.value.order.inputs = {
        ...formState.orderIntake.value.order.inputs,
        ...inputs,
      };
    } else if (
      formSettings.value.orderStrategy.modification.through ===
      "cancellation_reason"
    ) {
      let inputs = {};
      inputs.additional_items = [];
      inputs.custom_services = [];
      formState.orderIntake.value.order.inputs = {
        ...formState.orderIntake.value.order.inputs,
        ...inputs,
      };
      formState.orderIntake.value.order.job_id = store.getters.order.id;
      formState.orderIntake.value.order.cancellation_reason_id =
        formSettings.value.orderStrategy.modification.cancellationReasonId;
    } else {
      throw Error("Could not identify the modification strategy");
    }

    const file = new File(
      [JSON.stringify(formState.orderIntake.value.order)],
      "order",
      { type: "application/json" }
    );
    formState.orderIntake.value.formData.append("order", file);
  }

  function clearOrderIntakeOrderPayload() {
    formState.orderIntake.value.order = {
      preview: false,
      job_id: null,
      cancellation_reason_id: null,
      new_status: null,
      target: [],
      inputs: {
        products: [],
        services: [],
      },
    };
  }

  return {
    processOrderPayload,
    clearOrderIntakeOrderPayload,
  };
}
